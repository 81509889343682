html {
  color: #000;
  background: #fff;
}

body, div, dl, dt, dd, ul, li, ol, pre, code, form, fieldset, legend, input, button, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, th, var, optgroup {
  font-style: inherit;
  font-weight: inherit;
}

del, ins {
  text-decoration: none;
}

li {
  list-style-type: none;
}

caption, th {
  text-align: left;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  font-variant: normal;
  border: 0;
}

sup, sub {
  vertical-align: baseline;
}

legend {
  color: #000;
}

input, button, textarea, select, optgroup, option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

input, button, textarea, select {
  font-size: 100%;
}

a {
  outline: none;
}

a img {
  border: none;
}

a, a:visited {
  color: #e96151;
  text-decoration: none;
}

a:hover {
  color: #d94938;
}

h1, h2, h3, h4 {
  margin: 10px 0;
  padding: 5px 0 0;
}

h2 {
  margin: 40px 0 15px;
}

h3 {
  margin: 10px 0 15px;
}

p, ul, ol, table, dl {
  margin: 0 0 15px;
}

.tagline {
  color: #e96151;
  padding-left: 15px;
  font-size: 1.2em;
}

#nav .active {
  border-bottom: 1px dotted #e96151;
}

tt {
  background-color: #f2f2f2;
  padding: 1px 2px;
}

code, tt {
  font-family: Consolas, Inconsolata, Bitstream Vera Sans Mono, Menlo, Monaco, Andale Mono, Courier New, monospace;
  font-size: 12px;
}

pre {
  background: #f8f8f8;
  border-bottom: 1px dotted #e96151;
  margin: 0 0 25px;
  padding: 8px 15px;
  font-family: Consolas, Inconsolata, Bitstream Vera Sans Mono, Menlo, Monaco, Andale Mono, Courier New, monospace;
  font-size: 12px;
  line-height: 18px;
  overflow-x: auto;
}

html, body {
  color: #454545;
  background-color: #fff;
  min-height: 100%;
  font-family: Lato;
  font-weight: normal;
}

ul li:before {
  content: "-";
  margin-right: 5px;
  position: relative;
  left: 0;
}

ul li {
  text-indent: 0;
  line-height: 1.4em;
}

#container {
  margin: 25px auto 0;
  position: relative;
}

@media (width >= 1112px) {
  html, body {
    font-size: 14px;
  }

  #container {
    width: 80%;
    max-width: 1000px;
  }

  #content {
    float: left;
    width: 70%;
  }

  .sign {
    background-size: 50px 50px;
    padding-right: 45px;
    font-size: 55px;
  }

  #nav {
    padding-top: 90px;
  }

  #nav-wrap {
    float: left;
    width: 30%;
    min-width: 250px;
  }
}

@media (width <= 1112px) {
  html, body {
    font-size: 13px;
  }

  #container {
    width: 100%;
    min-width: 720px;
    max-width: 830px;
  }

  #content {
    float: left;
    width: 75%;
  }

  .sign {
    background-size: 35px 35px;
    padding-right: 35px;
    font-size: 40px;
  }

  #nav {
    padding-top: 90px;
  }

  #nav-wrap {
    float: left;
    width: 25%;
    min-width: 180px;
  }
}

#content, #nav {
  height: 100%;
}

#content p {
  line-height: 1.5em;
}

#text {
  background-color: #fff;
  width: 85%;
  padding: 0 20px;
}

.sign {
  text-align: right;
  color: #000;
  background-image: url("logo.fc3962ee.png");
  background-position: 100%;
  background-repeat: no-repeat;
  border-bottom: 1px dotted #454545;
  height: 70px;
  font-weight: 900;
  line-height: 70px;
}

@media (-webkit-device-pixel-ratio >= 2), (resolution >= 192dpi), (-moz-device-pixel-ratio >= 2) {
  .sign {
    background-image: url("logo_big.efc158fd.png");
  }
}

.sign .highlight {
  color: #e96151;
}

code .highlight {
  background-color: #ff0;
}

#nav-wrap {
  text-align: right;
  background-color: #fff;
}

#nav {
  text-transform: uppercase;
}

#nav a {
  color: #333;
  text-shadow: 1px 1px 1px 2px #ccc;
  border-bottom: 1px dotted #ccc;
  padding: 5px 15px 5px 5px;
  text-decoration: none;
  display: block;
}

#nav a:hover {
  color: #e96151;
}

.table-header {
  text-transform: uppercase;
  border-bottom: 1px dotted #e96151;
  margin-bottom: 10px;
}

.table-header .table-column {
  font-weight: bold;
}

.table-row {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 2px;
}

.table-column {
  float: left;
  padding: 4px 0;
}

.clear {
  clear: both;
}

#text {
  margin: 50px 0 100px;
}

input[type="text"] {
  vertical-align: middle;
  border: 0;
  border-bottom: 1px dotted #e96151;
  width: 226px;
  padding: 8px 6px;
  font-size: 14px;
}

input[type="text"]:focus {
  background-color: #f9f9f9;
  outline: none;
}

.downloads {
  background-color: #fafafa;
  border: 1px solid #eee;
  margin-bottom: 20px;
  padding: 10px;
}

.downloads h4 {
  margin-bottom: 10px;
  padding: 0;
}

.download {
  float: left;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #e96151;
  border-radius: 5px;
  margin: 0 10px 0 0;
  padding: 10px 15px;
  font-weight: 600;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
}

.download:hover {
  color: #d94938;
  border-color: #d94938;
}

.person {
  margin-bottom: 30px;
}

.person .img {
  background: 0 100% / cover no-repeat;
  border-bottom: 4px solid #e96151;
  height: 90px;
}

.person h3 {
  margin-bottom: 5px;
  padding: 0;
}

.person .img#michelle {
  background-image: url("mb.bab7632e.jpg");
}

.person .img#eric {
  background-image: url("ez.7b795061.jpg");
}

.random_tagline {
  color: #e96151;
  font-weight: 700;
}

.smbuttons {
  text-align: right;
  margin-top: 25px;
}

.warning {
  background-color: #f8f8f8;
  margin-bottom: 15px;
  padding: 10px;
  font-weight: bold;
}
/*# sourceMappingURL=style.5b9b39cd.css.map */
